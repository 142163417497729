<template>
  <div class="wrapper my-profile">
    <div class="relative title-wrap">
      <h3 class="page-titles">
        My profile
      </h3>
      <p>Below are your account and subscription plan details.</p>
      <div class="profile">
        <the-profile class="print:hidden" />
      </div>
    </div>

    <p class="mb-8 text-lg font-semibold">
      Account information
    </p>

    <table
      class="w-full text-base border border-collapse rounded table-auto"
      v-if="$store.getters['USER/isLoggedIn'] && !$store.state.USER.user.admin"
    >
      <tbody class="bg-white dark:bg-slate-800">
        <tr>
          <td
            class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
            width="350"
          >
            Name
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ `${$store.state.USER.user.firstName} ${$store.state.USER.user.lastName}` }}
          </td>
        </tr>
        <tr>
          <td
            class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
            width="350"
          >
            Organisation
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ `${$store.state.USER.user.organisationName}` }}
          </td>
        </tr>
        <tr>
          <td
            class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
            width="350"
          >
            Email
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ `${$store.state.USER.user.email}` }}
          </td>
        </tr>
      </tbody>
    </table>

    <table
      class="w-full text-base border border-collapse rounded table-auto"
      v-if="$store.getters['ADMIN/isLoggedAdminIn']"
    >
      <tbody class="bg-white dark:bg-slate-800">
        <tr>
          <td class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            Name
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ `${$store.state.ADMIN.admin.account.name}` }}
          </td>
        </tr>
        <tr>
          <td class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            Organisation
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            Admin
          </td>
        </tr>
        <tr>
          <td class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            Username
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ `${$store.state.ADMIN.admin.account.username}` }}
          </td>
        </tr>
      </tbody>
    </table>

    <p
      class="mt-20 mb-8 text-lg font-semibold"
    >
      Subscription plan
    </p>

    <table
      class="w-full text-base border border-collapse rounded table-auto"
    >
      <tbody class="bg-white dark:bg-slate-800">
        <tr>
          <td
            class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
            width="350"
          >
            Plan
          </td>
          <td
            v-if="!subscription.inTrial"
            class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
          >
            <span class="capitalize">{{ currentPlan.planName }}</span> ({{ currentPlan.commitment }} {{ currentPlan.currency }})
          </td>
          <td
            v-else
            class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
          >
            Free trial
          </td>
        </tr>
        <tr v-if="subscription.inTrial">
          <td
            class="p-8 pl-8 font-medium border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400"
            width="350"
          >
            Days left
          </td>
          <td class="p-8 pl-8 border-b border-slate-100 dark:border-slate-700 text-slate-500 dark:text-slate-400">
            {{ subscription.trialDaysRemaning }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex mt-20 space-x-2">
      <div
        v-if="isLoggedIn"
      >
        <button
          @click="openChargbeePortal"
          class="btn-outline-small"
        >
          Manage Billing
        </button>
      </div>
      <div>
        <button
          @click="$router.push({name: 'Checkout', query: {fromTrial: subscription.inTrial}})"
          class="btn-outline-small"
        >
          Change plan
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import TheProfile from '@/components/TheProfile.vue'

export default {
  components: { TheProfile },
  computed: {
    ...mapGetters({ order: 'ORDER/getOrder', isLoggedIn: 'USER/isLoggedIn', subscription: 'USER/getSubscription' }),
    currentPlan () {
      const [planName, currency, commitment] = this.subscription.plan.split('-')
      return {
        planName,
        currency,
        commitment
      }
    }
  },
  methods: {
    async updatePlan (event) {
      await this.$http.post('/subscriptions', { plan: event.target.value })
    },
    openChargbeePortal () {
      this.$chargebee.setPortalSession(() => {
        return this.$api.getChargebeePortalSession()
      })

      const chargbebeePortal = this.$chargebee.createChargebeePortal()
      chargbebeePortal.open({
        close: () => {
          this.$chargebee.logout()
        }
      })
    }

  }

}
</script>
